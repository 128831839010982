
import {
  TOPUP_MODAL_SHOW,
  TOPUP_MODAL_HIDE,
} from './types';

import axios from "axios";
import Cookies from 'js-cookie';

export const topUp = async (data) => {
   let url = `${process.env.REACT_APP_BACKEND_URL}/api/payment/create_payment/`;
   try {
     const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get('csrftoken'),
        Accept: "application/json"
      }
    });
    let paymentUrl
    if(data.method === "Paypay"){
      console.log("response.data", response.data)
      paymentUrl = response.data.link_url;
    }else if(data.method === "Hitpay"){
      paymentUrl = response.data.url;
    }
    return paymentUrl;
   } catch (error) {
     console.error("Payment initiation failed", error);
   }
};

export const checkPaymentStatus = async (token) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/api/payment/check_payment_status/`;
  try {
    const response = await axios.get(`${url}?token=${token}`, {
     headers: {
       "Content-Type": "application/json",
       "X-CSRFToken": Cookies.get('csrftoken'),
       Accept: "application/json"
     }
   });
    const paymentUrl = response.data.url;
    window.location.href = paymentUrl; // Redirect to HitPay's payment page
  } catch (error) {
    console.error("Payment initiation failed", error);
  }
} 

export const topupModalShow = () => (dispatch) => {
  dispatch({
    type: TOPUP_MODAL_SHOW,
  });
};

export const topupModalHide = () => (dispatch) => {
  dispatch({
    type: TOPUP_MODAL_HIDE,
  });
};

