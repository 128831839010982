import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en.json';
import cn from './locales/cn.json';
import hk from './locales/hk.json';
import ja from './locales/ja.json';

// Function to map country codes to language codes.
const getLanguageFromCountry = (countryCode) => {
  const countryToLang = {
    US: 'en',
    CN: 'cn',
    HK: 'hk',
    JP: 'ja',
  };
  return countryToLang[countryCode] || 'en'; // Default to English if not mapped.
};

// Custom function to detect language via geolocation.
const detectLanguage = async () => {
  try {
    const res = await fetch('https://ipapi.co/json/');
    const data = await res.json();
    // ipapi.co returns the country code in the "country" field.
    const countryCode = data.country ? data.country.toUpperCase() : 'US';
    const language = getLanguageFromCountry(countryCode);
    return language;
  } catch (error) {
    console.error('Error detecting language with ipapi.co:', error);
    return 'en'; // Fallback language if the API fails.
  }
};

const initializeI18n = async () => {
  // Wait for language detection to complete.
  // const detectedLng = await detectLanguage();

  // Initialize i18next with the detected language.
  await i18n
    .use(HttpApi) // Load translations from backend files if needed.
    .use(LanguageDetector) // Optional: can be used if you want fallback detection.
    .use(initReactI18next) // Pass the i18n instance to react-i18next.
    .init({
      resources: {
        en: { translation: en },
        cn: { translation: cn },
        hk: { translation: hk },
        ja: { translation: ja },
      },
      supportedLngs: ['en', 'cn', 'hk', 'ja'], // List of supported languages.
      fallbackLng: 'en', // Fallback language.
      lng: 'en', // Set the language dynamically based on geolocation.
      interpolation: {
        escapeValue: false, // React already escapes values.
      },
      // You can add additional configuration options here.
    });
};

initializeI18n();

export default i18n;
