import {
  Box,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Button,
  styled,
  Avatar,
  Divider,
  ListItemIcon,
  Select,
  InputAdornment,
  TextField
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import CheckIcon from '@mui/icons-material/Check';
import LockIcon from '@mui/icons-material/Lock';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectModel } from 'src/actions/settingAction';
import { topupModalShow } from 'src/actions/paymentAction';
import { loginModalShow } from 'src/actions/authAction';

import { useAuth } from 'src/contexts/AuthContext';
import { useSidebar } from 'src/contexts/SidebarContext';

const SearchInputWrapper = styled(TextField)(
  ({ theme }) => `
    border: 1px solid gray;
    background:white;
    border-radius:10px;
    color : black;
    margin-bottom: 10px;
    .MuiInputBase-input {
        border-radius:0 10px 10px 0 ;
        color:black;
        font-size: ${theme.typography.pxToRem(17)};
        background:white;
        color : black;
        padding-top:5px;
        padding-bottom:5px;
        color: '#2d333a';
        width:150px,
    },
   
`
);


function HeaderMenu() {

  const { user } = useAuth();
  const { toggleSidebar } = useSidebar();
  const dispatch = useDispatch();
  const model = useSelector((state) => state.setting.model);
  const [selectedModel, setSelectedModel] = useState({
    id: 'gpt-1',
    label: 'GPT-4o mini',
  });

  const models = [
    {
      category: 'Open AI',
      logo: '/static/images/logo/openai.svg',
      options: [
        { id: 'gpt-4o-mini', label: 'GPT-4o mini', icons: ['thumbUp'], requiresPayment: false },
        { id: 'o1-mini-2024-09-12', label: 'o1-mini', icons: ['lock'], requiresPayment: user?.balance > 0 ? false : true },
        { id: 'gpt-4o', label: 'GPT-4o(paid)', icons: ['thumbUp', 'lock'], requiresPayment: user?.balance > 0 ? false : true },
      ],
    },
    {
      category: 'Google',
      logo: '/static/images/logo/gemini.svg',
      options: [
        { id: 'gemini-1.5-flash', label: 'Gemini 1.5 Flash', icons: [], requiresPayment: false },
        { id: 'gemini-1.5-pro', label: 'Gemini 1.5 Pro', icons: ['lock'], requiresPayment: user?.balance > 0 ? false : true },
        { id: 'gemini-2.0-flash-exp', label: 'Gemini 2.0 Flash', icons: ['lock'], requiresPayment: user?.balance > 0 ? false : true },
      ],
    },
    {
      category: 'Meta',
      logo: '/static/images/logo/meta.svg',
      options: [
        { id: 'meta-llama/Llama-3.2-3B-Instruct-Turbo', label: 'Llama3.2-3b', icons: [], requiresPayment: false },
        { id: 'meta-llama/Meta-Llama-3.1-405B-Instruct-Turbo', label: 'Llama3.1-405b', icons: ['lock'], requiresPayment: user?.balance > 0 ? false : true },
      ],
    },
    {
      category: 'Deepseek',
      logo: '/static/images/logo/deepseek.png',
      options: [
        { id: 'deepseek/deepseek-chat', label: 'deepseek-chat', icons: [], requiresPayment: false },
      ],
    }
  ];
  
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const [searchValue, setSearchValue] = useState('');

  // Open and close menu handlers
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  // Handle model selection
  const handleModelSelect = (model) => {
    if(model.requiresPayment && !user){
      toggleSidebar()
      dispatch(loginModalShow());
      handleClose()
    }
    if(model.requiresPayment && user?.balance <= 0){
      dispatch(topupModalShow());
      handleClose()
    }
    if (!model.requiresPayment || user?.balance > 0) {
      dispatch(selectModel(model));
      setSelectedModel(model);
      handleClose();
    }
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const filteredModels = models.map(modelGroup => {
    const filteredOptions = modelGroup.options.filter(option =>
      option.label.toLowerCase().includes(searchValue.toLowerCase())
    );
    return { ...modelGroup, options: filteredOptions };
  }).filter(modelGroup => modelGroup.options.length > 0);

  // Render icons dynamically
  const renderIcons = (icons) => {
    return icons.map((icon, index) => {
      if (icon === 'thumbUp') return <ThumbUpIcon key={index} sx={{ color: '#686868' }} />;
      if (icon === 'lock' && !user?.balance > 0) return <LockIcon key={index} sx={{ color: '#686868' }} />;
      return null;
    });
  };

  useEffect(() => {
    setSelectedModel(model);
  });

  useEffect(() => {
    setSearchValue('')
  }, [ isOpen ])

  return (
    <>
      <Button
        sx={{
          display: 'block',
          color: '#265A9E',
          lineHeight: '18px',
          ml: { xs: '0px', md: '20px' },
          padding: { xs: '0px', md: '7px 10px !important' },
        }}
        ref={ref}
        onClick={handleOpen}
      >
        <Box display="flex" alignItems="center" color="black">
          <Typography sx={{ fontSize: { xs: 16, md: 18 }, fontWeight: 400 }}>{model.label}</Typography>
          <ExpandMoreTwoToneIcon fontSize="small" sx={{ pl: 0.3 }} />
        </Box>
      </Button>

      <Menu
        anchorEl={ref.current}
        sx={{
          mt: 1.5,
          '& .MuiAvatar-root': { width: 32, height: 32, ml: -0.5, mr: 1 },
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        }}
        open={isOpen}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <SearchInputWrapper
          value={searchValue}
          // autoFocus
          autoComplete={false}
          onChange={handleSearchChange}
          sx={{
            '& .MuiInputBase-input': {
              
              width:'150px',
              color: '#2d333a',
              '&::placeholder': {
                color: 'black',
                opacity: 1,
              },
              '&:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 100px rgb(255, 255, 255) inset',
                WebkitTextFillColor: 'black',
                caretColor: 'black',
                borderRadius: 'inherit',
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchTwoToneIcon sx={{ color: "black" }} />
              </InputAdornment>
            )
          }}
          placeholder={t('search')}
          fullWidth
        />
        {filteredModels.map((modelGroup) => (
          <Box key={modelGroup.category} sx={{ mb: 1 }}>
            <Box display="flex" alignItems="center" sx={{ color: 'black', mb: 1 }}>
              <Box
                component="img"
                sx={{ height: 20, width: 20, mr: 1 }}
                alt={modelGroup.category}
                src={modelGroup.logo}
              />
              <Typography fontSize={16}>{modelGroup.category}</Typography>
            </Box>

            {/* Options */}
            {modelGroup.options.map((option) => (
              <MenuItem
                key={option.id}
                // disabled={option.requiresPayment}
                sx={{
                  '&.Mui-disabled': {
                    opacity: 0.7
                  },
                  padding: '3px 10px',
                  color: selectedModel.id === option.id ? 'black !important' : 'gray !important',
                  gap: 1,
                  minHeight: 'auto',
                  // pointerEvents: !user?.balance > 0 && option.requiresPayment ? 'none' : 'auto', // Disable interaction for unpaid options
                }}
                onClick={() => handleModelSelect(option)}
              >
                <CheckIcon
                  sx={{
                    opacity: selectedModel.id === option.id ? 1 : 0,
                    color: 'black',
                  }}
                />
                {option.label}
                {renderIcons(option.icons)}
              </MenuItem>
            ))}
          </Box>
        ))}
      </Menu>
    </>
  );
}

export default HeaderMenu;