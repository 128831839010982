import { useState, createContext, useContext } from 'react';

export const SidebarContext = createContext({});

export const SidebarProvider = ({ children }) => {
  
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [dragToggle, setDragToggle] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  const toggleDrag = () => {
    setDragToggle(!dragToggle);
  };
  const closeDrag = () => {
    setDragToggle(false);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) setSelectedFile(selectedFile);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile) setSelectedFile(droppedFile);
  };

  return (
    <SidebarContext.Provider
      value={{ sidebarToggle, toggleSidebar, closeSidebar, dragToggle,toggleDrag, closeDrag, handleFileChange, handleDrop, selectedFile }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => useContext(SidebarContext);