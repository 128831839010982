import { Box, Button, TextField, Typography } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useAuth } from "src/contexts/AuthContext";
import { useTranslation } from 'react-i18next';
import { useInviteInteraction } from "src/contexts/InviteContext";

function SignUp({ switchSignpage }) {
   const { inviteCode, mustSignedInvitedUser } = useInviteInteraction();
   const { registerUser } = useAuth();
   const { t } = useTranslation();

   const validationSchema = Yup.object({
      firstName: Yup.string().required(t('firstNameRequired')),
      lastName: Yup.string().required(t('lastNameRequired')),
      emailAddress: Yup.string().email(t('invalidEmailAddress')).required(t('emailRequired')),
      password: Yup.string().min(8, t('passwordMinLength')).required(t('passwordRequired')),
      confirmPassword: Yup.string()
         .oneOf([Yup.ref('password'), null], t('passwordsMustMatch'))
         .required(t('confirmPasswordRequired')),
   });

   const handleSubmit = async (values, { setSubmitting }) => {
      if (inviteCode && mustSignedInvitedUser) {
         values.invite_code = inviteCode;
      }
      try {
         const data = {
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.emailAddress,
            password: values.password,
         }
         const res = await registerUser(data);
         if (res) switchSignpage(true);
      } catch (error) {
         console.error(t("Registration Error:"), error);
      } finally {
         setSubmitting(false);
      }
   };

   return (
      <Box>
         <Typography fontWeight={700} fontSize={24} sx={{ mb:'20px', mt:'30px', color: '#2d333a' }}>
            {t('createAnAccount')}
         </Typography>
         <Formik
            initialValues={{
               firstName: '',
               lastName: '',
               emailAddress: '',
               password: '',
               confirmPassword: ''
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
         >
            {({ isSubmitting }) => (
               <Form>
                  {['firstName', 'lastName', 'emailAddress', 'password', 'confirmPassword'].map((field) => (
                     <Field key={field} name={field}>
                        {({ field, meta }) => (
                           <TextField
                              {...field}
                              type={field.name.includes("word") ? "password" : "text"}
                              label={t(field.name)}
                              fullWidth
                              size='small'
                              error={meta.touched && Boolean(meta.error)}
                              helperText={meta.touched && meta.error}
                              InputLabelProps={{ sx: { color: '#2d333a !important' } }}
                              sx={{
                                 mb:1,
                                 '& .MuiOutlinedInput-root': {
                                    '& fieldset': { borderColor: 'gray !important' },
                                    '&:hover fieldset': { borderColor: 'black' },
                                    '&.Mui-focused fieldset': { borderColor: '#10a37f' },
                                 },
                                 '& .MuiInputBase-input': {
                                    color: '#2d333a',
                                    '&::placeholder': {
                                       color: 'black',
                                       opacity: 1,
                                    },
                                    '&:-webkit-autofill': {
                                       WebkitBoxShadow: '0 0 0 100px #fff inset',
                                       WebkitTextFillColor: 'gray',
                                       caretColor: 'gray',
                                       borderRadius: 'inherit',
                                    },
                                 },
                              }}
                           />
                        )}
                     </Field>
                  ))}

                  <Button
                     type="submit"
                     variant="contained"
                     fullWidth
                     disabled={isSubmitting}
                     sx={{ background: '#10a37f', color: 'white', mt: 0.5 }}
                  >
                     {isSubmitting ? t('signingUp') : t('signUp')}
                  </Button>
               </Form>
            )}
         </Formik>
      </Box>
   );
}

export default SignUp;
