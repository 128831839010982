
import { forwardRef, useRef, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  InputBase,
  Paper,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useAuth } from 'src/contexts/AuthContext';
import { getInviteCode } from 'src/actions/settingAction';
import i18next from 'i18next'; 


function InviteButton() {

  const { t, i18n } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { user } = useAuth();
  const inviteURl = process.env.REACT_APP_INVITE_LINK_URL;
  const [inviteLink, setInviteLink] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  
  const currentLang = useSelector((state) => state.setting.language);

  const handleOpen = async () => {
    setOpen(true);

    const code = await getInviteCode();
    setInviteCode(code);
    setInviteLink(inviteURl + code);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(inviteLink);
    alert(i18next.t("Invite link copied to clipboard!"));
  };

  const handleCopyCode = () => {
    navigator.clipboard.writeText(inviteCode);
    alert(i18next.t('Invite code copied to clipboard!'));
  };

  const ButtonContent = (lang) => {
    switch (lang) {
      case "en":
        return (
          <>
            <span>Invite a friend</span><br/>
            <span>Get</span>&nbsp;
            <span style={{color:'#378DFD'}}>60% off</span>
          </>
        )
      case "cn":
        return (
          <>
            <span>邀请好友享受</span><br/>
            <span style={{color:'#378DFD'}}>六折优惠</span>
          </>
        )
      case "hk":
        return (
          <>
            <span>邀請好友享受</span><br/>
            <span style={{color:'#378DFD'}}>六折優惠</span>
          </>
        )
      case "ja":
        return (
          <>
            <span>友達を招待する</span><br/>
            <span style={{color:'#378DFD'}}>60%オフをゲット</span>
          </>
        )
      default:
        return (
          <>
            <span>Invite a friend</span><br/>
            <span>Get</span>&nbsp;
            <span style={{color:'#378DFD'}}>60% off</span>
          </>
        )
        
    }
  };

  return (
    <>
     {user && (
       <Button 
       sx={{
         display:'block',
         color:'#265A9E',
         lineHeight:'18px',
         fontSize: {
           xs : 12,
           md:14,
         },
         ml: {
           xs: '0px',
           md: '20px',
         },
         padding: {
           xs: '0px 0px 0px 0px !important',
           md: '5px 10px !important',
         },
         borderRadius: '20px',
         border: {
           xs: 'none',
           md: '2px solid #378DFD',
         }
         }}
         ref={ref}
         onClick={handleOpen}
       >
         {ButtonContent(currentLang)}
     </Button>
     )}

      <Dialog
        maxWidth='xs'
        sx={{
          borderRadius: '0px !important',
        }}
        onClose={handleClose} open={isOpen}>
        <DialogContent
          sx={{
            background: 'white',
            padding: 3
          }}>
          <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
            <Typography color="black" variant="subtitle1" mb={1}>
            {t('inviteDscription')}
            </Typography>
          </DialogContentText>
          <Box mb={2}>
            <Typography fontSize={14} fontWeight={400} color="#83848C" mb={1} >{t('copyLink')}</Typography>
            <Paper
              component="form"
              sx={{p: '0px 4px', color:'black', display: 'flex', alignItems: 'center', borderColor:"black !important", borderRadius:0}}
            > 
              <InputBase
                sx={{ ml: 1, flex: 1, color:'black'}}
                type="text" value={inviteLink} readOnly
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton color="primary" sx={{ p: '10px', color: '#000000'}} aria-label="directions"
                onClick={handleCopyLink}
              >
                <FileUploadOutlinedIcon />
              </IconButton>
            </Paper>
          </Box>
          <Box>
            <Typography fontSize={14} fontWeight={400} color="#83848C" mb={1} >{t('shareInviteCode')}</Typography>
            <Paper
              component="div"
              sx={{p: '0px 4px', color:'black', display: 'flex', alignItems: 'center', borderColor:"black !important", borderRadius:0}}
            > 
              <InputBase
                autoComplete='off'
                sx={{ ml: 1, flex: 1, color:'black'}}
                type="text" value={inviteCode} readOnly
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton color="primary" sx={{ p: '10px', color: '#000000'}} aria-label="directions"
                onClick={handleCopyCode}
              >
                <FileUploadOutlinedIcon />
              </IconButton>
            </Paper>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default InviteButton;
