import { Box, alpha, lighten, Input, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';
import { useDevice } from 'src/contexts/deviceContext';
import { useEffect, useState } from 'react';

const SidebarLayout = () => {
  const theme = useTheme();
  const { isMobile } = useDevice();

  useEffect(() => {
    const handleResize = () => {
      if (window.visualViewport) {
        const viewportHeight = window.visualViewport.height;
        document.documentElement.style.height = `${viewportHeight}px`;
      }
    };

    window.visualViewport.addEventListener('resize', handleResize);
    return () => window.visualViewport.removeEventListener('resize', handleResize);
  }, [window.visualViewport]);

  return (
    <>
      <Box
        sx={{
          flex: 1,
          minHeight: '100dvh !important',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                  lighten(theme.colors.primary.main, 0.7),
                  0.15
                )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                  theme.colors.alpha.black[100],
                  0.1
                )}, 0px 5px 12px -4px ${alpha(
                  theme.colors.alpha.black[100],
                  0.05
                )}`
          }
        }}
      >
        <Header />
        {
          isMobile && <Sidebar />
        }
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: {
              xs: `${theme.header.mobileHeight}`,
              md: `${theme.header.height}`
            },
            // [theme.breakpoints.up('lg')]: {
            // },
          }}
        >
          <Box display="block">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
