import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import SignIn from './SignIn';
import SignUp from './SignUp';
import SocialAuth from './SocialAuth';
import SendEmail from './SendEmail';
import VerifyCode from './VerifyCode';
import ResetPassword from './ResetPassword';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/contexts/AuthContext';
import { useInviteInteraction } from 'src/contexts/InviteContext';
import { useDispatch, useSelector } from 'react-redux';
import { cleanChatContent } from 'src/actions/chatAction';
import { loginModalShow, loginModalHide } from 'src/actions/authAction';
import { selectModel } from 'src/actions/settingAction';

function AuthDialog() {
  const dispatch = useDispatch();
  const { user, logoutUser } = useAuth();
  
  const { mustSignedInvitedUser } = useInviteInteraction();
  // const [isMainDialogOpen, setMainDialogOpen] = useState(false);
  const isMainDialogOpen = useSelector((state) => state.auth.isOpenLoginModel)
  const [isSecondaryDialogOpen, setSecondaryDialogOpen] = useState(false);
  const [activeForm, setActiveForm] = useState('sendEmail');
  const [isSignIn, setIsSignIn] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (mustSignedInvitedUser) {
      openMainDialog();
    }
  }, [mustSignedInvitedUser]);

  const openMainDialog = () => {
    // setMainDialogOpen(true);
    dispatch(loginModalShow());

    setIsSignIn(true);
    setActiveForm('sendEmail');
  };

  const closeMainDialog = () => {
    dispatch(loginModalHide());
    // setMainDialogOpen(false);
  }

  const openSecondaryDialog = () => setSecondaryDialogOpen(true);

  const closeSecondaryDialog = () => setSecondaryDialogOpen(false);

  const toggleFormType = (type) => setActiveForm(type);

  const switchBetweenDialogs = (type) => {
    if (type === 'resetPassword') {
      // setMainDialogOpen(false);
      dispatch(loginModalHide());
      setSecondaryDialogOpen(true);
    } else {
      // setMainDialogOpen(true);
      
      dispatch(loginModalShow());
      setSecondaryDialogOpen(false);
    }
  };

  const renderActiveFormComponent = () => {
    switch (activeForm) {
      case 'sendEmail':
        return <SendEmail switchForm={() => toggleFormType('verifyCode')} />;
      case 'verifyCode':
        return <VerifyCode switchForm={() => toggleFormType('resetPassword')} />;
      case 'resetPassword':
        return <ResetPassword modalClose={closeSecondaryDialog} />;
      default:
        return null;
    }
  };

  const userLogOut = () => {
    logoutUser();
    dispatch(cleanChatContent());
    dispatch(selectModel(
      {
        id: 'gpt-4o-mini', 
        label: 'GPT-4o mini', 
        icons: [],
        requiresPayment: false
      }));
  }

  return (
    <>
      {user ? (
        <Button
        onClick={userLogOut}
          sx={{ background: '#265A9E', color: 'white' }}
          fullWidth
          variant="contained"
          size="medium"
        >
          {t('logOut')}
        </Button>
      ) : (
        <Button
          onClick={openMainDialog}
          sx={{ background: '#265A9E', color: 'white' }}
          fullWidth
          variant="contained"
          size="medium"
        >
          {t('signIn')}
        </Button>
      )}

      <Dialog
        maxWidth="xs"
        sx={{ borderRadius: '0px !important' }}
        onClose={closeMainDialog}
        open={isMainDialogOpen}
      >
        <DialogContent sx={{textAlign:'center', background: 'white', padding: 3, maxWidth: 400 }}>
          <Box display="flex" justifyContent="center">
            <Typography fontWeight={700} fontSize={30} sx={{ color: '#265A9E' }}>
              OneDollar
            </Typography>
            <Typography fontWeight={700} fontSize={30} sx={{ color: '#227ff3' }}>
              AI
            </Typography>
          </Box>
          {isSignIn ? (
            <>
              <SignIn modalClose={closeMainDialog} switchDialog={() => switchBetweenDialogs('resetPassword')} />
              <Box sx={{ display: 'flex', mb: 2, color: 'black', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="body2">{t('haveNotAccount')}&nbsp;</Typography>
                <Button
                  sx={{ color: '#10a37f' }}
                  size="small"
                  onClick={() => setIsSignIn(!isSignIn)}
                >
                  {t('signUp')}
                </Button>
              </Box>
            </>
          ) : (
            <>
              <SignUp switchSignpage={setIsSignIn} />
              <Box sx={{ display: 'flex', color: 'black', mb: 2, justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="body2">{t('haveAccount')}&nbsp;</Typography>
                <Button
                  sx={{ color: '#10a37f' }}
                  size="small"
                  onClick={() => setIsSignIn(!isSignIn)}
                >
                  {t('signIn')}
                </Button>
              </Box>
            </>
          )}
          <Divider style={{ background: '#dbdbdb', color: 'black', marginBottom: '16px' }}>{t('or')}</Divider>
          <SocialAuth isSignIn={isSignIn} modalClose={closeMainDialog} />
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth="xs"
        sx={{ borderRadius: '0px !important' }}
        open={isSecondaryDialogOpen}
      >
        <DialogContent sx={{ background: 'white', padding: 4, maxWidth: 400 }}>
          <IconButton sx={{ float: 'right', mr: -1, mt: -1 }} onClick={closeSecondaryDialog}>
            <CloseIcon />
          </IconButton>
          <Box display="flex" justifyContent="center" mb={2}>
            <Typography fontWeight={700} fontSize={30} sx={{ color: '#265A9E' }}>
              OneDollar
            </Typography>
            <Typography fontWeight={700} fontSize={30} sx={{ color: '#227ff3' }}>
              AI
            </Typography>
          </Box>
          {renderActiveFormComponent()}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AuthDialog;
