import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, REST_USER, LOGIN_MODAL_SHOW, LOGIN_MODAL_HIDE } from 'src/actions/types';

const initialState = {
  user: null,
  isAuthenticated: false,
  error: null,
  isOpenLoginModel : false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        error: null,
      };
    case REST_USER:
      return {
        ...state,
        user: action.payload
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        error: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        error: null,
      };
    
    case LOGIN_MODAL_SHOW:
      return {
        ...state,
        isOpenLoginModel : true
      };
    case LOGIN_MODAL_HIDE:
      return {
        ...state,
        isOpenLoginModel : false
      };
    default:
      return state;
  }
};

export default authReducer;