import {
  SET_MODEL,
  TOOGLE_LANAUAGE,
  SET_LANGUAGE,
  TOPUP_MODAL_SHOW,
  TOPUP_MODAL_HIDE
} from 'src/actions/types';

const initialState = {
   model : {
    id: 'gpt-4o-mini', 
    label: 'GPT-4o mini', 
    icons: [],
    requiresPayment: false
  },
  toogleLanguage : false,
  language : 'en',
  topupModalShow : false
};


const modelReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_MODEL:
      return {
        ...state,
        model : action.payload.model
      };

    case TOOGLE_LANAUAGE:
      return {
        ...state,
        toogleLanguage : action.payload.val
      }
    
    case SET_LANGUAGE:
      return {
        ...state,
        language : action.payload.lang
      } 
    
    case TOPUP_MODAL_SHOW:
      return {
        ...state,
        topupModalShow : true
      } 
    case TOPUP_MODAL_HIDE:
      return {
        ...state,
        topupModalShow : false
      } 

    default:
      return state;
  }
};

export default modelReducer;
