import { useEffect, useState, useRef } from 'react';

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useSidebar } from 'src/contexts/SidebarContext';
import { useDevice } from 'src/contexts/deviceContext';


import HeaderButtons from './Buttons';
// import HeaderUserbox from './Userbox';
import HeaderMenu from './Menu';
import HeaderText from './Text';
import TopUp from './Buttons/TopUp';


const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        // height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: 0;
            width: auto;
        }
`
);

function Header() {
  const { sidebarToggle, toggleSidebar, dragToggle, toggleDrag } = useSidebar();
  const { settingHeight } = useDevice();
  const headerRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    const handleResize = () => {
      if (headerRef.current) {
        settingHeight(headerRef.current.offsetHeight);
      }
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <HeaderWrapper
      alignItems="center"
      ref={headerRef}
      sx={{
        height:{
          xs: `${theme.header.mobileHeight}`,
          md: `${theme.header.height}`
        },
        padding:{
          xs : '20px 20px 0px 20px',
          md : '25px 20px 20px 20px',
        },
        zIndex:6,
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.2
              )}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}`,
        background: 'white',
        display: "flex",
        flexDirection:{
          xs:'column',
          md:'row'
        },
      }}
    >
      <Box 
        sx={{
          width : {
            xs: '100%',
            md:'300px',
          },
          borderBottom: {
            xs: '1px solid #66708526',
            md: 'none'
          },
          padding: {
            xs:'0px 0px 15px 0px !important',
            md :'0px 0px 0px 0px !important'
          },
          justifyContent:'center',
          display:'flex',
          // flexDirection:'column',
          alignItems:'center',
        }} 
        >
        {/* <HeaderUserbox /> */}
        <Box
          component="span"
          sx={{
            display: { lg: 'none', xs: 'inline-block' }
          }}
        >
          {/* <Tooltip arrow title="Toggle Menu"> */}
            <IconButton sx={{mr:3}} color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          {/* </Tooltip> */}
        </Box>
        <HeaderText />
        <IconButton  sx={{ml:3}} color="primary" onClick={toggleDrag}>
          {dragToggle ? (
            <img src='/static/images/chat.png' alt='chat' />
          ) : (
            <img src='/static/images/binoculars.png' alt='binoculars' />
          )}
        </IconButton>
      </Box>
      <Stack
        sx={{
          mt:{
            xs:0,
            md:0,
          },
          width:'100%',
          display:'flex',
          flex:'1 1 0%',
          justifyContent:'space-between'
        }}
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        
        <HeaderButtons />
        <HeaderMenu />
        <Box
          sx={{
            display: {
              xs: 'block',
              md: 'none',
            },
          }}
        >
          <TopUp />
        </Box>
      </Stack>
    </HeaderWrapper>
  );
}

export default Header;
