import { createContext, useState, useEffect, useContext } from "react";
import swal from 'sweetalert2';
import Cookies from 'js-cookie';
import i18next from 'i18next'; 
import { getNewAccessTokenWithRefreshToken } from "src/services/authService"
const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {

    const [authTokens, setAuthTokens] = useState(() => 
        localStorage.getItem("access") || null
    );

    const [refreshToken, setRefreshToken] = useState(
        localStorage.getItem("refresh") || null
    );

    const [user, setUser] = useState( localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")) || null);

    const [loading, setLoading] = useState(true);

    const [verifyCode, setVerifyCode] = useState(null);

    const [email, setEmail] = useState(null);

    const loginUser = async (userInfo) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}/api/auth/login/`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": Cookies.get('csrftoken') // Include the CSRF token in the headers
            },
            body: JSON.stringify(userInfo)
        });
        const data = await response.json();
        if (response.status === 201 || response.status === 200) {
            setUser(data.user);
            setRefreshToken(data.refresh);
            setAuthTokens(data.access);
            localStorage.setItem("user", JSON.stringify(data.user));
            localStorage.setItem("access", data.access);
            localStorage.setItem("refresh", data.refresh);
            swal.fire({
                title: i18next.t("loginSuccess"),
                icon: "success",
                toast: true,
                timer: 6000,
                position: 'bottom-right',
                timerProgressBar: true,
                showConfirmButton: false
            });
            return true
        } else {
            swal.fire({
                title: i18next.t(data.message),
                icon: "error",
                toast: true,
                timer: 6000,
                position: 'bottom-right',
                timerProgressBar: true,
                showConfirmButton: false
            });
            return false
        }
    };

    const registerUser =  async (data) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}/api/auth/register/`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: 'application/json',
                "X-CSRFToken": Cookies.get('csrftoken') // Include the CSRF token in the headers
            },
            body: JSON.stringify(data)
        });

        const resdata = await response.json();

        if (response.status === 201 || response.status === 200) {
            swal.fire({
                title: i18next.t(resdata.state ? "registerSuccess" : "registerFailed"),
                icon: resdata.state ? "success" : "error",
                toast: true,
                timer: 6000,
                position: 'bottom-right',
                timerProgressBar: true,
                showConfirmButton: false
            });
            
            return true 
        } else {
            if(resdata.email){
                swal.fire({
                    title: i18next.t(resdata.email),
                    icon: "error",
                    toast: true,
                    timer: 6000,
                    position: 'bottom-right',
                    timerProgressBar: true,
                    showConfirmButton: false
                });
            }
            if (resdata.password){
                swal.fire({
                    title: i18next.t(resdata.password),
                    icon: "error",
                    toast: true,
                    timer: 6000,
                    position: 'bottom-right',
                    timerProgressBar: true,
                    showConfirmButton: false
                });
            }
            return false
        }
    };

    const sendEmail = async (email) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}/api/auth/send_email/`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: email })
        });
        const data = await response.json();
        if (response.status === 201 || response.status === 200) {
            setEmail(data.email);
            swal.fire({
                title: i18next.t("Sending Email Success!"),
                icon: "success",
                toast: true,
                timer: 6000,
                position: 'bottom-right',
                timerProgressBar: true,
                showConfirmButton: false
            });
            return data.email;
        } else {
            swal.fire({
                title: i18next.t("No Account Found"),
                icon: "error",
                toast: true,
                timer: 6000,
                position: 'bottom-right',
                timerProgressBar: true,
                showConfirmButton: false
            });
            return false
        }
    }

    const sendVerifyCode = async (verifyData) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}/api/auth/send_otp_code/`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": Cookies.get('csrftoken') // Include the CSRF token in the headers
            },
            body: JSON.stringify(verifyData)
        });
        const data = await response.json();
        if (response.status === 201 || response.status === 200) {
            swal.fire({
                title: i18next.t("Verification Success!"),
                icon: "success",
                toast: true,
                timer: 6000,
                position: 'bottom-right',
                timerProgressBar: true,
                showConfirmButton: false
            });
            return data
        } else {
            console.log("An Error Occured");
            swal.fire({
                title: i18next.t("Verification Failed!"),
                icon: "error",
                toast: true,
                timer: 6000,
                position: 'bottom-right',
                timerProgressBar: true,
                showConfirmButton: false
            });
            return false
        }
    }

    const resetPassword = async (sendData, isPrefix = true) => {
        console.log("senda", sendData);
        let url = `${process.env.REACT_APP_BACKEND_URL}/api/auth/reset_password/`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": Cookies.get('csrftoken') // Include the CSRF token in the headers
            },
            body: JSON.stringify(sendData)
        });
        const data = await response.json();
        if (response.status === 201 || response.status === 200) {
            setUser(data.user);
            setRefreshToken(data.refresh);
            setAuthTokens(data.access);
            localStorage.setItem("user", JSON.stringify(data.user));
            localStorage.setItem("access", data.access);
            localStorage.setItem("refresh", data.refresh);
            swal.fire({
                title: i18next.t(isPrefix ? "loginSuccess" : "Change Password Success!"),
                icon: "success",
                toast: true,
                timer: 6000,
                position: 'bottom-right',
                customClass: {
                    popup: 'swal-popup',
                    container: 'swal-container',
                    title: 'swal-title',
                    icon: 'swal-icon'
                },
                timerProgressBar: true,
                showConfirmButton: false
            });
            if (data.message) {
                swal.fire({
                    title: i18next.t(data.message),
                    icon: data.state ? "success" : "error",
                    timer: 6000,
                    position: 'center',
                    timerProgressBar: false,
                    showConfirmButton: false
                });
            }
            return true
        } else {
            console.log("An Error Occured");
            swal.fire({
                title: i18next.t("No Account Found"),
                icon: "error",
                toast: true,
                timer: 6000,
                position: 'bottom-right',
                timerProgressBar: true,
                showConfirmButton: false
            });
            return false
        }
    }

    const socialLogin = async (tokenData) => {
    
        try {
            const res = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/api/auth/social/sign/`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        "X-CSRFToken": Cookies.get('csrftoken') // Include the CSRF token in the headers
                    },
                    body: JSON.stringify(tokenData)
                }
            )
            const jsson = await res.json();
            if (res.status !== 200) {
                return { success: false, message: res.error, data: null };
            }
            if (jsson.access) {
                setAuthTokens(jsson.access)
                setRefreshToken(jsson.refresh)
                setUser(jsson.data)
                localStorage.setItem('access', jsson.access);
                localStorage.setItem('refresh', jsson.refresh);
                localStorage.setItem('user', JSON.stringify(jsson.data))

                // and handle invite process
                if (jsson.message) {
                    swal.fire({
                        title: i18next.t(jsson.state ? "loginSuccess" : "loginFailed"),
                        icon: jsson.state ? "success" : "error",
                        timer: 6000,
                        toast: true,
                        position: 'bottom-right',
                        timerProgressBar: true,
                        showConfirmButton: false
                    });
                }
            }
            return { success: true, message: "success", data: jsson };
        } catch (err) {
            console.log(err);
            return { success: false, message: err.message, data: null };
        }
    };

    const logoutUser = () => {
        setAuthTokens(null);
        setUser(null);
        setRefreshToken(null);
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        localStorage.removeItem("user");

        swal.fire({
            title: i18next.t("You have been logged out"),
            icon: "success",
            toast: true,
            timer: 6000,
            position: 'bottom-right',
            timerProgressBar: true,
            showConfirmButton: false,
            // didClose: () => {
            //     window.location.href="/api/auth/logout"
            // }
        });
    };

    const getUserData = async () => {
        let url = `${process.env.REACT_APP_BACKEND_URL}/api/auth/get_user_data/`;
    
        // Function to get the CSRF token from cookies
        const getCookie = (name) => {
            let cookieValue = null;
            if (document.cookie && document.cookie !== '') {
                const cookies = document.cookie.split(';');
                for (let i = 0; i < cookies.length; i++) {
                    const cookie = cookies[i].trim();
                    if (cookie.substring(0, name.length + 1) === (name + '=')) {
                        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                        break;
                    }
                }
            }
            return cookieValue;
        };
    
        const csrftoken = getCookie('csrftoken');
    
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrftoken // Include the CSRF token in the headers
            },
            body: JSON.stringify({ user_id: user.id })
        });
    
        if (response.ok) {
            const data = await response.json();
            localStorage.setItem("user", JSON.stringify(data.userdata));
            setUser(data.userdata);
            return data.userdata;
        } else {
            console.error('Failed to fetch user data:', response.statusText);
        }
    };
    

    useEffect(() => {
        const checkTokenValidity = async () => {
            if (refreshToken) {
                getUserData()
                await getNewAccessTokenWithRefreshToken(refreshToken).then((res) => {
                    if (!res) return logoutUser()
                    localStorage.setItem("access", (res.access));
                    localStorage.setItem("refresh", (res.refresh));
                })
            }
        };

        checkTokenValidity();
        setLoading(false);
    }, [refreshToken]);

    return (
        <AuthContext.Provider value={{ 
            email, user, authTokens,
            setUser, socialLogin, getUserData, sendEmail, sendVerifyCode, resetPassword,
            setAuthTokens, registerUser, loginUser, logoutUser 
        }}>
            {loading ? null : children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);