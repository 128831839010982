
import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, REST_USER, LOGIN_MODAL_SHOW, LOGIN_MODAL_HIDE } from './types';


export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

export const reSetUser = (user) => ({
  type : REST_USER,
  payload: user
})

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const logout = () => ({
  type: LOGOUT,
});

export const loginModalShow = () => ({
  type : LOGIN_MODAL_SHOW
})

export const loginModalHide = () => ({
  type : LOGIN_MODAL_HIDE
})