import { useState, createContext, useContext, useEffect } from 'react';

export const DeviceContext = createContext({});

export const DeviceProvider = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const {children} = props;

  const settingHeight = (height) => {
    setHeaderHeight(height);
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1280 ? true : false);
    };
  
    handleResize();
    window.addEventListener('resize', handleResize);
  
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <DeviceContext.Provider value={{ isMobile, headerHeight, settingHeight }}>
      {children}
    </DeviceContext.Provider>
  );
};

export const useDevice = () => useContext(DeviceContext);
