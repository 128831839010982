import {
  SET_MESSAGES,
  ADD_SUB_MESSAGE,
  SET_INCREASE_CHAT_ID,
  SET_ACTIVECHAT_ID,
  SET_NEW_ACTIVECHAT_ID,
  DELETE_CHAT,
  SET_CHAT_LOG,
  CLEAN_CHAT_CONTENT,
} from './types';

import Cookies from 'js-cookie';

export const setMessages = ({message}) => async (dispatch) => {
  dispatch({
    type: SET_MESSAGES,
    payload: { newMessage: message }
  });
};

export const getUserChat = (user_id) => async (dispatch) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/get_user_chat/${user_id}`,
        {
          headers: {
            "X-CSRFToken": Cookies.get('csrftoken') // Include the CSRF token in the headers
          },
        }
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch user chat logs: ${response.statusText}`);
    }
    const data = await response.json(); // Add `await` here
    const chat_log = data.chat_logs;
    dispatch({
      type : SET_CHAT_LOG,
      payload : {chat_log},
    })
    // Dispatch or use data as needed
    if (dispatch) {
      dispatch({ type: "SET_USER_CHAT", payload: data });
    }
  } catch (error) {
    console.error("Error fetching user chat:", error); // Proper error logging
  }
};


export const sendMessage = (formData, user_id = null, chat_id = null) => async (dispatch) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/chat/`,
      {
        method: 'POST',
        body : formData,
        headers: {
            "X-CSRFToken": Cookies.get('csrftoken') // Include the CSRF token in the headers
        },
      }
    );

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let partialMessage = '';
    let k = 0;
    let active_id = 0;

    while (true) {
      const { value, done } = await reader.read();
      if (done) break;

      const text = decoder.decode(value);
      const lines = (partialMessage + text).split('\n');
      partialMessage = lines.pop(); // Keep the last partial line

      for (const line of lines) {
        if (line.trim() && k === 0) {
          try {
            const chunk = JSON.parse(line);
            if(chunk.created_chat_id){
              active_id = chunk.created_chat_id;
              console.log("active_id", active_id);
            }
            dispatch({
              type: SET_MESSAGES,
              payload: { newMessage: { role: 'assistant', content: chunk.message } }
            });
          } catch (e) {
            console.error('Error parsing JSON:', e);
          }
        } else if (line.trim()) {
          try {
            const chunk = JSON.parse(line);
            if(chunk.created_chat_id){
              active_id = chunk.created_chat_id;
            }
            if(chunk.message !== undefined){
              dispatch({
                type: ADD_SUB_MESSAGE,
                payload: { message: { role: 'assistant', content: chunk.message } }
              });
            }
          } catch (e) {
            console.error('Error parsing JSON:', e);
          }
        }

        k++;
      }
      if(chat_id === null && user_id){
        dispatch(getUserChat(user_id))
      }
    }

    if(active_id !== 0){
      dispatch({
        type: SET_NEW_ACTIVECHAT_ID,
        payload: { chatId : active_id }
      });
    }
    
    return true;

  } catch (error) {
    console.error(error);
    dispatch({
      type: SET_MESSAGES,
      payload: {
        newMessage: { role: 'assistant', content: 'Sorry, something went wrong.' }
      }
    });
  }
};

export const increaseChatId = () => (dispatch) => {
  dispatch({
    type: SET_INCREASE_CHAT_ID
  });
};

export const cleanChatContent = () => (dispatch) => {
  dispatch({
    type: CLEAN_CHAT_CONTENT
  });
}

export const setActiveGuestChatId = (chat_id) => async (dispatch) => {
  dispatch({
    type: SET_ACTIVECHAT_ID,
    payload: { chatId : chat_id}
  });
}

export const setActiveChatId = (chat_id, user_id) => async (dispatch) => {
  if(!user_id){
    dispatch({
      type: SET_ACTIVECHAT_ID,
      payload: { chatId : chat_id}
    });
  }else{
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/get_active_chat/${chat_id}`,
        {
          headers: {
            "X-CSRFToken": Cookies.get('csrftoken') // Include the CSRF token in the headers
          },
        }
      );
  
      if (!response.ok) {
        throw new Error(`Failed to fetch user chat logs: ${response.statusText}`);
      }
      const data = await response.json(); // Add `await` here
      console.log("chat", data.active_chat);
      dispatch({
          type: SET_ACTIVECHAT_ID,
          payload: { chatId : chat_id, activeChat : data.active_chat }
        });
    } catch (error) {
      console.error("Error fetching user chat:", error); // Proper error logging
    }
  }
  
};

export const deleteChat =  ({chat_id, user_id}) => async(dispatch) => {
  console.log("ggg", chat_id)
  dispatch({
    type: DELETE_CHAT,
    payload: { chat_id }
  });

  if(user_id){
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/delete_user_chat/${chat_id}/${user_id}`,
        {
          headers: {
            "X-CSRFToken": Cookies.get('csrftoken') // Include the CSRF token in the headers
          },
        }
      );
  
      if (!response.ok) {
        throw new Error(`Failed to fetch user chat logs: ${response.statusText}`);
      }
  
      dispatch(getUserChat(user_id))
    } catch (error) {
      console.error("Error fetching user chat:", error); // Proper error logging
    }
  }
  
};
