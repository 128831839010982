import {
  SET_MESSAGES,
  ADD_SUB_MESSAGE,
  SET_INCREASE_CHAT_ID,
  SET_ACTIVECHAT_ID,
  SET_NEW_ACTIVECHAT_ID,
  DELETE_CHAT,
  SET_CHAT_LOG,
  CLEAN_CHAT_CONTENT
} from 'src/actions/types';

const initialState = {
  messages: localStorage.getItem('chatHistory')
    ? JSON.parse(localStorage.getItem('chatHistory'))[0].messages
    : [],
  activeChatId: null, // indicates the id of the selected chat from chat history,
  chatHistory: localStorage.getItem('chatHistory')
    ? JSON.parse(localStorage.getItem('chatHistory'))
    : [],

  userChatLog : [],
  selectedChatLog : [],
};


const updateChatHistory = (
  chatHistory,
  activeChatId,
  newMessage,
  isSubMessage = false
) => {
  return chatHistory.map((chat) => {
    if (chat.id === activeChatId) {
      const messages = isSubMessage
        ? [...chat.messages.slice(0, -1), newMessage]
        : [...chat.messages, newMessage];
      return { ...chat, messages };
    }
    return chat;
  });
};

const saveToLocalStorage = (data) => {
  localStorage.setItem('chatHistory', JSON.stringify(data));
};

const chatReducer = (state = initialState, action) => {
  const chatHistory = JSON.parse(localStorage.getItem('chatHistory')) || [];

  switch (action.type) {
    case SET_MESSAGES: {
      const { newMessage } = action.payload;
      let newChatId;
      let updatedData;
      if(state.activeChatId === null){
        newChatId = state.chatHistory.length > 0 ? (state.chatHistory[state.chatHistory.length-1].id + 1) : 1;
        updatedData = [...state.chatHistory, {
          id : newChatId,
          title : newMessage['content'],
          messagees : []
        }]
      } else {
        newChatId = state.activeChatId
        updatedData = state.chatHistory.map(item => {
          if (item.id === state.activeChatId) {
            return { ...item, messagees : [...state.selectedChatLog, newMessage] }; // Update the title
          }
          return item; // Return the item unchanged if it doesn't match
        });
      }
      
      return {
        ...state,
        chatHistory: updatedData,
        activeChatId : newChatId,
        selectedChatLog : [...state.selectedChatLog, newMessage]
      }
    }

    case ADD_SUB_MESSAGE: {
      const { message } = action.payload;
      const lastMessage = state.selectedChatLog[state.selectedChatLog.length - 1];
      
      const updatedLastMessage = {
        ...lastMessage,
        content: lastMessage.content + message.content
      };

      const updated_slectedChatLog = [...state.selectedChatLog.slice(0, -1), updatedLastMessage]
      const data = state.chatHistory.map(item => {
        if(item.id == state.activeChatId){
          return {...item, messagees: updated_slectedChatLog}
        }
        return item
      })
      return {
        ...state,
        chatHistory : data,
        selectedChatLog : updated_slectedChatLog,
      };
    }

    case SET_INCREASE_CHAT_ID:
      const newChatId = state.chatHistory.length > 0 ? state.chatHistory.length : 0;

      return {
        ...state,
        messages : [],
        selectedChatLog : [],
        activeChatId : null,
      }

    case SET_ACTIVECHAT_ID:
      const chatId = action.payload.chatId;
      const activeChat = action.payload.activeChat;
      if(activeChat !== undefined){
        return {
          ...state,
          activeChatId: action.payload.chatId,
          selectedChatLog : activeChat.formatted_messages,
        };
      }else{
        return {
          ...state,
          activeChatId: chatId,
          selectedChatLog : state.chatHistory.filter(item => item.id === chatId)[0].messagees
        };
      }
    
    case SET_NEW_ACTIVECHAT_ID:
      return {
        ...state,
        activeChatId: action.payload.chatId,
      };
    
    case DELETE_CHAT:
      const { chat_id } = action.payload;
      console.log("chat_id", chat_id)
      // const filteredData = state.chatHistory.filter(item => item.id !== chat_id);
      return {
        ...state,
        chatHistory : state.chatHistory.filter(item => item.id !== chat_id),
        activeChatId : state.activeChatId === chat_id ? null : state.activeChatId,
        selectedChatLog : state.activeChatId === chat_id ? [] : state.selectedChatLog
      }
      const updated_chatHistory = state.chatHistory.filter(
        (chat) => chat.id !== chat_id
      );

      // saveToLocalStorage(updated_chatHistory);

      return {
        ...state,
        chatHistory: updated_chatHistory
      };
    
    case CLEAN_CHAT_CONTENT:
      return {
        ...state,
        chatHistory: [],
        userChatLog : [],
        selectedChatLog : [],
        activeChatId : null,
      }

    case SET_CHAT_LOG :
        return {
          ...state,
          userChatLog : action.payload.chat_log
        }
    default:
      return state;
  }
};

export default chatReducer;
